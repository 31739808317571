// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

// Example
$('#slick-home').slick({
  slidesToShow: 3,
  slidesToScroll: 3,
  dots: false,
  fade: false,
  arrows: true,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  appendArrows: '#slick-home-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite prev"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite next"></span></button>',
  responsive: [{
      breakpoint: 1200,
      settings: {}
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
});

// Example animations
setTimeout(function() {
  $('#slick .slick-active').addClass('animation');
}, 250);

$('#slick').on('afterChange', function(event, slick, currentSlide) {
  $('#slick .slick-active').addClass('animation');
});

$('#slick').on('beforeChange', function(event, slick, currentSlide) {
  $('#slick .slick-active').removeClass('animation');
});
