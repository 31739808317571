// Navigation mobile
function nav_mobile() {
  $('#nav-mobile-trigger').on('click', function() {
    $('body').toggleClass('menu-open');
    $('#nav-main').toggle();
  });
}

// Trigger resa inner
function resa_inner_trigger(){
  $('#trigger-resa-inner').on('click', function(){
    $('#resa-inner').removeClass('closed');
  });
  $('#trigger-resa-inner-close').on('click', function(){
    $('#resa-inner').addClass('closed');
  });
}

// Navigation size
function nav_size() {
  var position = $(window).scrollTop();
  if (position > 220) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
  }
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
    }
  });
}

// Navigation sub menu
function nav_sub_menu() {
  var viewport = $(window).width();
  if (viewport < 576) {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      $(this).next().toggle();
      return false;
    });
  } else {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      var navSub = $(this).parent();
      var isFocus = navSub.hasClass('focus');
      $('#nav-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      event.stopPropagation();
      return false;
    });
    $('html').click(function() {
      $('#nav-main li[data-navsub]').removeClass('focus');
    });
  }
}

// Scroll top button
function scroll_top() {
  $('#btn-back-top').fadeOut(0);
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('#btn-back-top').fadeIn(300);
    } else {
      $('#btn-back-top').fadeOut(300);
    }
  });
  $('#btn-back-top').on('click', function() {
    $('html, body').animate({
      scrollTop: 0
    }, '500');
    return false;
  });
}

function f_resa_options() {
    $('#options input[type=checkbox]').on('change', function () {
        checkbox($(this));
        request();
    });

    $('#options .quantite select').on('change', function () {
        $(this).parent().parent().parent().parent().find('input').prop('value', $(this).val());
        request();
    });

    // Initialisation
    request();
    $('#options input[type=checkbox]').each(function () {
        checkbox($(this));
    });

    function checkbox(self) {
        var select = self.parent().parent().find('select');
        var quantite = self.parent().parent().find('.quantite select');
        if (self.is(":checked")) {
            select.prop('disabled', false);
            select.parent().parent().parent().removeClass('disabled');
        } else {
            select.prop('disabled', true);
            select.parent().parent().parent().addClass('disabled');
        }
        self.prop('value', quantite.val());
    }

    function request() {
        $.ajax({
            method: "POST",
            url: $("#options").data('ajax-url'),
            data: $("#devis").serialize(),
            success: function(data) {
                $("#total").text(data.total);
                $("#options-montant").text(data.options);
                var options_element = $("#options-montant").parent().parent();
                if (data.options == 0) {
                    options_element.hide();
                } else {
                    options_element.show();
                }
                $("#assurances-montant").text(data.assurances);
                var assurances_element = $("#assurances-montant").parent().parent();
                if (data.assurances == 0) {
                    assurances_element.hide();
                } else {
                    assurances_element.show();
                }
                $("#reductions-montant").text(data.reduction_montant);
                var reduction_element = $("#reductions-montant").parent().parent();
                if (data.reduction_montant == 0) {
                    reduction_element.hide();
                } else {
                    reduction_element.show();
                }
                var promotions = '';
                if (data.promotions) {
                    promotions = '* ';
                    data.promotions.forEach(function(promotion){
                        promotions += promotion.nom + ' : ' + promotion.extrait + ' ; ';
                    });
                }
                $("#reductions-description").text(promotions);
            }
        });
    }

}

if ($('#options').length > 0) {
    f_resa_options();
}

// Initialisation
nav_mobile();
nav_sub_menu();
nav_size();
scroll_top();
resa_inner_trigger();
