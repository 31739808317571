// Configuration
WebFontConfig = {
  google: {
    families: ['Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700', 'Material+Icons']
  }
};

// Initialisation
(function() {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();
